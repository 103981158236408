import styled from '@emotion/styled';
import { Paper, IconButton } from '@mui/material';

export const MessagePopupContainer = styled(Paper, {
  shouldForwardProp: prop => 
    !['side', 'horizontalMargin', 'verticalMargin', 'fabSize', 'backgroundColor', 'textColor', 'fontFamily'].includes(prop)
})`
  position: fixed;
  z-index: 9998;
  bottom: ${({ verticalMargin, fabSize }) => verticalMargin + fabSize / 2}px;
  ${({ side, horizontalMargin, fabSize }) => {
    const sideStyles = {
      left: `left: ${horizontalMargin + fabSize + 10}px;`,
      right: `right: ${horizontalMargin + fabSize + 10}px;`,
    };
    return sideStyles[side];
  }}
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  font-family: ${({ fontFamily }) => fontFamily};
  cursor: pointer;
`;

export const CloseButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'textColor'
})`
  position: absolute;
  top: -2px;
  right: -2px;
  color: ${({ textColor }) => textColor};
`;
