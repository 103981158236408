import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const TypingIndicatorWrapper = styled(Box, {
  shouldForwardProp: prop => !['side', 'horizontalMargin', 'verticalMargin', 'backgroundColor'].includes(prop)
})`
  position: fixed;
  bottom: ${({ verticalMargin }) => verticalMargin + 28}px;
  ${({ side, horizontalMargin }) => {
    const sideStyles = {
      left: `left: ${horizontalMargin + 70}px;`,
      right: `right: ${horizontalMargin + 70}px;`,
    };
    return sideStyles[side];
  }}
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 5px 10px;
  border-radius: 12px;
`;
