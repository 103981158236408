import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Menu, MenuItem, Tooltip } from '@mui/material';
import { 
  Close as CloseIcon, 
  MoreHoriz as MoreHorizIcon, 
  Refresh as RefreshIcon, 
  Security as SecurityIcon 
} from '@mui/icons-material';
import { 
  ChatHeaderContainer, 
  ChatHeaderLogo, 
  ChatHeaderMenu, 
  ChatHeaderClose 
} from '../styles/ChatHeaderStyles';

const MENU_ITEMS = [
  {
    id: 'reset',
    label: 'Reset gesprek',
    Icon: RefreshIcon,
    action: 'onReset',
    ariaLabel: 'Reset conversation',
  },
  {
    id: 'privacy',
    label: 'Privacy',
    Icon: SecurityIcon,
    action: 'onPrivacyClick',
    ariaLabel: 'View privacy information',
  },
];

const ChatHeader = ({
  assistantPicture,
  assistantName,
  color,
  backgroundColor,
  onClose,
  onReset,
  onPrivacyClick,
  isResetting = false,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuAction = useCallback((actionName) => {
    const actions = { onReset, onPrivacyClick };
    handleMenuClose();
    actions[actionName]?.();
  }, [onReset, onPrivacyClick, handleMenuClose]);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Escape') {
      handleMenuClose();
    }
  }, [handleMenuClose]);

  return (
    <ChatHeaderContainer 
      style={{ backgroundColor }}
      role="banner"
      aria-label="Chat header"
    >
      <Box 
        display="flex" 
        alignItems="center"
        component="div"
        aria-label="Assistant information"
      >
        <ChatHeaderLogo
          src={assistantPicture}
          alt={`${assistantName} avatar`}
          onError={(e) => {
            e.target.onerror = null;
          }}
        />
      </Box>

      <Typography 
        variant="h6" 
        style={{ 
          textAlign: 'center', 
          color, 
          flex: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        component="h1"
      >
        {assistantName}
      </Typography>

      <ChatHeaderMenu
        onClick={handleMenuOpen}
        style={{ color }}
        disabled={disabled}
        aria-label="Open menu"
        aria-controls={isMenuOpen ? 'chat-menu' : undefined}
        aria-expanded={isMenuOpen}
        aria-haspopup="true"
      >
        <MoreHorizIcon />
      </ChatHeaderMenu>

      <Menu
        id="chat-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        onKeyDown={handleKeyPress}
        style={{ zIndex: 99999 }}
        MenuListProps={{
          'aria-label': 'Chat options',
          role: 'menu',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {MENU_ITEMS.map(({ id, label, Icon, action, ariaLabel }) => (
          <MenuItem
            key={id}
            onClick={() => handleMenuAction(action)}
            disabled={isResetting && id === 'reset'}
            role="menuitem"
            aria-label={ariaLabel}
          >
            <Icon style={{ marginRight: '8px' }} />
            {label}
          </MenuItem>
        ))}
      </Menu>

      <ChatHeaderClose
        onClick={onClose}
        style={{ color }}
        disabled={disabled}
        aria-label="Close chat"
      >
        <CloseIcon />
      </ChatHeaderClose>
    </ChatHeaderContainer>
  );
};

ChatHeader.propTypes = {
  assistantPicture: PropTypes.string.isRequired,
  assistantName: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onPrivacyClick: PropTypes.func.isRequired,
  isResetting: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default memo(ChatHeader);
