import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TypingIndicator from './TypingIndicator';
import { TypingBubbleContainer, TypingBubblePaper } from '../styles/TypingBubbleStyles';

/**
 * TypingBubble component displays a chat bubble containing a typing indicator
 * Used to show that the chat bot is preparing a response
 *
 * @param {Object} props - Component props
 * @param {string} props.backgroundColor - Background color of the typing bubble
 * @param {string} [props.className] - Additional CSS class name
 * @param {Object} [props.style] - Additional inline styles 
 */
const TypingBubble = ({ 
  backgroundColor,
  className = '',
  style = {},
  ariaLabel = 'Bot is typing...'
}) => (
  <TypingBubbleContainer
    className={className}
    role="status"
    aria-live="polite"
  >
    <TypingBubblePaper 
      style={{ 
        backgroundColor,
        ...style 
      }}
    >
      <TypingIndicator ariaLabel={ariaLabel} />
    </TypingBubblePaper>
  </TypingBubbleContainer>
);

TypingBubble.propTypes = {
  /** Background color of the typing bubble */
  backgroundColor: PropTypes.string.isRequired,
  /** Additional CSS class name */
  className: PropTypes.string,
  /** Additional inline styles */
  style: PropTypes.object,
  /** Accessibility label for screen readers */
  ariaLabel: PropTypes.string,
};

export default memo(TypingBubble);
