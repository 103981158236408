import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Close as CloseIcon } from '@mui/icons-material';
import { MessagePopupContainer, CloseButton } from '../styles/MessagePopupStyles';

const MessagePopup = ({
  side = 'right',
  horizontalMargin = 20,
  verticalMargin = 20,
  fabSize = 56,
  backgroundColor = '#ffffff',
  textColor = '#000000',
  fontFamily = 'inherit',
  popupMessage,
  onClose,
  onClick,
}) => {
  const handleCloseClick = useCallback((e) => {
    e.stopPropagation();
    onClose?.();
  }, [onClose]);

  const handleContainerClick = useCallback((e) => {
    onClick?.(e);
  }, [onClick]);

  return (
    <MessagePopupContainer
      role="dialog"
      aria-label="Chat message popup"
      side={side}
      horizontalMargin={horizontalMargin}
      verticalMargin={verticalMargin}
      fabSize={fabSize}
      backgroundColor={backgroundColor}
      textColor={textColor}
      fontFamily={fontFamily}
      onClick={handleContainerClick}
    >
      {popupMessage}
      <CloseButton
        size="small"
        textColor={textColor}
        onClick={handleCloseClick}
        aria-label="Close popup"
      >
        <CloseIcon fontSize="inherit" />
      </CloseButton>
    </MessagePopupContainer>
  );
};

MessagePopup.propTypes = {
  side: PropTypes.oneOf(['left', 'right']),
  horizontalMargin: PropTypes.number,
  verticalMargin: PropTypes.number,
  fabSize: PropTypes.number,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  fontFamily: PropTypes.string,
  popupMessage: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(MessagePopup);
