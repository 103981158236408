import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, CircularProgress } from '@mui/material';
import { StyledChatFab, UnreadIndicator } from '../styles/ChatFabStyles';

/**
 * ChatFab component displays a floating action button with an assistant's image
 * and optional unread message indicator
 */
const ChatFab = ({
  onClick,
  customization,
  showUnreadIndicator = false,
  unreadCount = 1,
  disabled = false,
  isLoading = false,
}) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = useCallback(() => {
    setImageError(true);
    console.error('Failed to load assistant image:', customization.assistantPicture);
  }, [customization.assistantPicture]);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onClick();
    }
  }, [onClick]);

  return (
    <StyledChatFab
      onClick={disabled ? undefined : onClick}
      side={customization.openButtonSide}
      horizontalMargin={customization.openButtonHorizontalMargin}
      verticalMargin={customization.openButtonVerticalMargin}
      color={customization.secondaryBackgroundColor}
      disabled={disabled}
      style={{ zIndex: 99997 }}
      role="button"
      tabIndex={0}
      onKeyPress={handleKeyPress}
      aria-label={`${customization.assistantName || 'Chat'} ${showUnreadIndicator ? 'has unread messages' : ''}`}
      aria-disabled={disabled}
      className={isLoading ? 'loading' : ''}
    >
      {isLoading ? (
        <CircularProgress
          size={36}
          color="inherit"
          aria-label="Loading chat"
        />
      ) : (
        <>
          {!imageError ? (
            <img
              src={customization.assistantPicture}
              alt={`${customization.assistantName || 'Assistant'} avatar`}
              onError={handleImageError}
              style={{ 
                width: '100%', 
                height: '100%', 
                borderRadius: '50%',
                opacity: disabled ? 0.5 : 1,
                transition: 'opacity 0.3s ease',
              }}
            />
          ) : (
            <Typography
              variant="h6"
              style={{
                color: customization.primaryTextColor,
                opacity: disabled ? 0.5 : 1,
              }}
            >
              {(customization.assistantName || 'Chat').charAt(0)}
            </Typography>
          )}
          {showUnreadIndicator && !disabled && (
            <UnreadIndicator
              role="status"
              aria-label={`${unreadCount || 1} unread message${unreadCount !== 1 ? 's' : ''}`}
              style={{
                backgroundColor: customization.unreadIndicatorColor || '#ff4444',
              }}
            >
              <Typography 
                variant="caption"
                component="span"
              >
                {unreadCount || 1}
              </Typography>
            </UnreadIndicator>
          )}
        </>
      )}
    </StyledChatFab>
  );
};

ChatFab.propTypes = {
  /** Click handler for the FAB */
  onClick: PropTypes.func.isRequired,
  /** Customization options for the chat interface */
  customization: PropTypes.shape({
    assistantPicture: PropTypes.string,
    assistantName: PropTypes.string,
    openButtonSide: PropTypes.oneOf(['left', 'right']),
    openButtonHorizontalMargin: PropTypes.number,
    openButtonVerticalMargin: PropTypes.number,
    secondaryBackgroundColor: PropTypes.string,
    primaryTextColor: PropTypes.string,
    unreadIndicatorColor: PropTypes.string,
  }).isRequired,
  /** Whether to show the unread message indicator */
  showUnreadIndicator: PropTypes.bool,
  /** Number of unread messages */
  unreadCount: PropTypes.number,
  /** Whether the FAB is disabled */
  disabled: PropTypes.bool,
  /** Whether the chat is loading */
  isLoading: PropTypes.bool,
};

export default memo(ChatFab);
