import styled from '@emotion/styled';
import { Box, TextField, IconButton } from '@mui/material';

export const InputAreaContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
`;

export const MessageField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 10px;
    min-height: 40px; /* Match height of send button */
    max-height: 80px;
  }

  .MuiOutlinedInput-input {
    border: none !important;
    margin-bottom: 0 !important;
    padding: 16.5px 14px !important;
    color: black !important;
    min-height: 24px !important;
    max-height: 24px !important;
    overflow-y: auto;
  }

  .MuiOutlinedInput-notchedOutline {
    margin-bottom: 0 !important;
  }
`;

export const SendButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'color'
})`
  margin-left: 10px;
  width: 40px; /* Fixed width */
  height: 40px; /* Fixed height */
  padding: 8px; /* Adjusted padding */
`;
