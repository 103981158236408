import React, { useState, useRef, useEffect, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@mui/material';
import { ThumbUp, ThumbDown, Error } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import TypingBubble from './TypingBubble';
import {
  MessageListContainer,
  MessageContainer,
  MessagePaper,
  FeedbackContainer,
  RoleButtonsContainer,
  RoleButton,
  ErrorMessage
} from '../styles/MessageListStyles';

const addTrackingParam = (url, source = 'ai_assistent') => {
  try {
    const urlObj = new URL(url);
    urlObj.searchParams.set('utm_source', source);
    return urlObj.toString();
  } catch (e) {
    console.warn('Invalid URL:', url);
    return url;
  }
};

/**
 * Individual message component
 */
const Message = memo(({ 
  message, 
  styles, 
  onFeedback
}) => {
  const handlePositiveFeedback = useCallback(() => {
    if (!message.feedback) {
      onFeedback(message.messageId, 'positive');
    }
  }, [onFeedback, message]);

  const handleNegativeFeedback = useCallback(() => {
    if (!message.feedback) {
      onFeedback(message.messageId, 'negative');
    }
  }, [onFeedback, message]);

  if (message.type === 'error') {
    return (
      <ErrorMessage>
        <Error style={{ marginRight: '10px' }} />
        {message.text}
      </ErrorMessage>
    );
  }

  return (
    <MessagePaper
      sender={message.sender}
      style={styles}
      role="listitem"
      aria-label={`Message from ${message.sender}`}
    >
      <ReactMarkdown
        children={DOMPurify.sanitize(message.text)}
        components={{
          p: ({ node, ...props }) => (
            <Typography variant="body1" {...props} style={{ marginBottom: '12px' }} />
          ),
          ul: ({ node, ...props }) => (
            <Typography variant="body1" component="ul" {...props} style={{ marginBottom: '12px' }} />
          ),
          li: ({ node, ...props }) => (
            <Typography variant="body1" component="li" {...props} style={{ marginBottom: '4px' }} />
          ),
          a: ({ node, children, href, ...props }) => (
            <a 
              {...props} 
              href={addTrackingParam(href)}
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ textDecoration: 'underline' }}
            >
              {children}
            </a>
          ),
        }}
      />
      {message.sender === 'bot' && message.type === 'normal' && message.messageId && (
        <FeedbackContainer>
          {(!message.feedback || message.feedback === 'positive') && (
            <IconButton
              size="small"
              onClick={handlePositiveFeedback}
              disabled={!!message.feedback}
              aria-label="Positive feedback"
            >
              <ThumbUp 
                fontSize="small" 
                color="grey"
              />
            </IconButton>
          )}
          {(!message.feedback || message.feedback === 'negative') && (
            <IconButton
              size="small"
              onClick={handleNegativeFeedback}
              disabled={!!message.feedback}
              aria-label="Negative feedback"
            >
              <ThumbDown 
                fontSize="small" 
                color="grey"
              />
            </IconButton>
          )}
        </FeedbackContainer>
      )}
    </MessagePaper>
  );
});

/**
 * Role selection component
 */
const RoleSelection = memo(({ 
  roleOptions, 
  onRoleSelection, 
  styles,
  selectedRole 
}) => (
  <RoleButtonsContainer role="group" aria-label="Role selection">
    {!selectedRole && (
      <Typography 
        variant="body1" 
        style={{ 
          width: '100%',
          marginLeft: '8px',
        }}
      >
        Ben jij een...
      </Typography>
    )}
    {roleOptions.map((role) => (
      <RoleButton
        key={role.value}
        onClick={() => !selectedRole && onRoleSelection(role)}
        bgcolor={styles.bgcolor}
        textcolor={styles.textcolor}
        bordercolor={styles.bordercolor}
        disabled={!!selectedRole}
        isSelected={selectedRole?.value === role.value}
        isHidden={!!selectedRole && selectedRole.value !== role.value}
      >
        {role.label}
      </RoleButton>
    ))}
  </RoleButtonsContainer>
));

/**
 * Custom hook for managing scroll behavior
 */
const useMessageScroll = (messages, isTyping) => {
  const messageListRef = useRef(null);

  useEffect(() => {
    const scrollToBottom = () => {
      if (messageListRef.current) {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }
    };

    if (isTyping || messages.length > 0) {
      scrollToBottom();
    }
  }, [isTyping, messages]);

  return messageListRef;
};

/**
 * MessageList component displays a list of chat messages with feedback options
 * and role selection capabilities
 */
const MessageList = ({
  messages,
  backgroundColor,
  primaryBackgroundColor,
  primaryTextColor,
  secondaryBackgroundColor,
  secondaryTextColor,
  onFeedback,
  isTyping,
  roleOptions,
  onRoleSelection,
  roleButtonStyles,
  selectedRole,
}) => {
  const messageListRef = useMessageScroll(messages, isTyping);

  const messageStyles = {
    primaryBackgroundColor,
    primaryTextColor,
    secondaryBackgroundColor,
    secondaryTextColor
  };

  return (
    <MessageListContainer 
      ref={messageListRef} 
      style={{ backgroundColor }} 
      role="log"
      aria-live="polite"
      aria-label="Message list"
    >
      {messages.map((message, index) => (
        <React.Fragment key={index}>
          <MessageContainer sender={message.sender}>
            <Message
              message={message}
              styles={messageStyles}
              onFeedback={onFeedback}
            />
          </MessageContainer>
          {message.type === 'welcome' && roleOptions && (
            <RoleSelection
              roleOptions={roleOptions}
              onRoleSelection={onRoleSelection}
              styles={roleButtonStyles}
              selectedRole={selectedRole}
            />
          )}
        </React.Fragment>
      ))}
      {isTyping && <TypingBubble backgroundColor={primaryBackgroundColor} />}
    </MessageListContainer>
  );
};

MessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    sender: PropTypes.oneOf(['user', 'bot']).isRequired,
    type: PropTypes.oneOf(['normal', 'error', 'warning', 'welcome', 'privacy', 'role_selection']).isRequired,
    messageId: PropTypes.string,
    feedback: PropTypes.oneOf(['positive', 'negative', null]),
  })).isRequired,
  backgroundColor: PropTypes.string.isRequired,
  primaryBackgroundColor: PropTypes.string.isRequired,
  primaryTextColor: PropTypes.string.isRequired,
  secondaryBackgroundColor: PropTypes.string.isRequired,
  secondaryTextColor: PropTypes.string.isRequired,
  onFeedback: PropTypes.func.isRequired,
  isTyping: PropTypes.bool.isRequired,
  roleOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    message: PropTypes.string,
  })),
  onRoleSelection: PropTypes.func,
  roleButtonStyles: PropTypes.shape({
    bgcolor: PropTypes.string,
    textcolor: PropTypes.string,
    bordercolor: PropTypes.string,
  }),
  selectedRole: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    message: PropTypes.string,
  }),
};

export default memo(MessageList);
