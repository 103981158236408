import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@mui/material';
import { StyledChatFooter } from '../styles/ChatFooterStyles';

const ChatFooter = ({
  prefix = 'Powered by',
  linkText = 'AI voor Impact',
  linkUrl = 'https://aivoorimpact.nl',
  backgroundColor = 'transparent',
  textColor = 'grey',
  linkColor = 'grey',
  isDisabled = false,
  className = '',
  style = {},
}) => (
  <StyledChatFooter
    role="contentinfo"
    className={className}
    style={{
      backgroundColor,
      ...style,
    }}
  >
    <Typography
      variant="body2"
      component="span"
      sx={{ 
        fontSize: '12px',
        color: 'grey'
      }}
    >
      {prefix}{' '}
    </Typography>
    <Link
      href={linkUrl}
      target="_blank"
      rel="noopener noreferrer sponsored"
      underline="hover"
      onClick={(e) => {
        if (isDisabled) {
          e.preventDefault();
        }
      }}
      aria-label={`Visit ${linkText} website`}
      style={{
        pointerEvents: isDisabled ? 'none' : 'auto',
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      <Typography
        variant="body2"
        component="strong"
        sx={{ 
          fontSize: '12px',
          fontWeight: 'bold',
          color: 'grey'
        }}
      >
        {linkText}
      </Typography>
    </Link>
  </StyledChatFooter>
);

ChatFooter.propTypes = {
  prefix: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  linkColor: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default memo(ChatFooter);
