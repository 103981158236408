import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, useCallback } from 'react';
import ChatDrawer from './components/ChatDrawer';
import ChatFab from './components/ChatFab';
import MessagePopup from './components/MessagePopup';
import TypingIndicator from './components/TypingIndicator';
import getConfigByClientId from './config';
import { TypingIndicatorWrapper } from './styles/ChatInterfaceStyles';

const DEFAULT_WELCOME_MESSAGE = "Hello there! 👋 It's nice to meet you! What brings you here today?";
const WARNING_TIMEOUT_MS = 10000;
const TYPING_ANIMATION_DURATION = 2000;

const ChatInterface = forwardRef(({ clientId }, ref) => {
  // UI State
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isTypingIndicatorVisible, setIsTypingIndicatorVisible] = useState(false);
  const [showPopupMessage, setShowPopupMessage] = useState(false);
  const [showUnreadIndicator, setShowUnreadIndicator] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  // Chat State
  const [messages, setMessages] = useState([]);
  const [customization, setCustomization] = useState({});
  const [apiEndpoint, setApiEndpoint] = useState('');
  const [sessionId, setSessionId] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [warningTimeout, setWarningTimeout] = useState(null);
  const warningTimeoutRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openChatInterface: () => setOpen(true)
  }));

  const getWelcomeMessage = useCallback(() => ({
    text: customization.welcomeMessage || DEFAULT_WELCOME_MESSAGE,
    sender: 'bot',
    type: 'welcome',
  }), [customization.welcomeMessage]);

  const handleApiError = useCallback((error, customMessage) => {
    console.error(customMessage, error);
    setMessages(prevMessages => {
      const filteredMessages = prevMessages.filter(msg => msg.type !== 'warning');
      return [
        ...filteredMessages,
        {
          text: customMessage,
          sender: 'bot',
          type: 'error',
        },
      ];
    });
  }, []);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await getConfigByClientId(clientId);
        setCustomization(config.customization);
        setApiEndpoint(config.apiEndpoint);
      } catch (error) {
        handleApiError(error, 'Error fetching configuration');
      }
    };
    fetchConfig();
  }, [clientId, handleApiError]);

  useEffect(() => {
    const loadExistingSession = async () => {
      const savedSessionId = localStorage.getItem('chatSessionId');
      if (!open || !apiEndpoint) return;

      if (savedSessionId) {
        setIsLoadingHistory(true);
        try {
          const response = await fetch(`${apiEndpoint}get_chat_session?session_id=${savedSessionId}`);
          const data = await response.json();
          
          if (response.ok) {
            setSessionId(data.session_id);
            setMessages([getWelcomeMessage(), ...data.history]);
            if (data.role) {
              const selectedRoleOption = customization.roleOptions?.find(
                option => option.value === data.role
              );
              setSelectedRole(selectedRoleOption);
            }
          } else {
            localStorage.removeItem('chatSessionId');
            setSessionId(null);
            setMessages([getWelcomeMessage()]);
          }
        } catch (error) {
          handleApiError(error, 'Error loading chat session');
          setMessages([getWelcomeMessage()]);
        } finally {
          setIsLoadingHistory(false);
        }
      } else {
        setMessages([getWelcomeMessage()]);
      }
    };

    loadExistingSession();
  }, [open, apiEndpoint, getWelcomeMessage, handleApiError, customization.roleOptions]);

  useEffect(() => {
    const handleFocus = () => {
      if (open && sessionId && apiEndpoint) {
        const loadSession = async () => {
          try {
            const response = await fetch(`${apiEndpoint}get_chat_session?session_id=${sessionId}`);
            const data = await response.json();
            
            if (response.ok) {
              setMessages([getWelcomeMessage(), ...data.history]);
              // Check if there's a role in the session data and it's different from current role
              if (data.role && (!selectedRole || selectedRole.value !== data.role)) {
                const selectedRoleOption = customization.roleOptions?.find(
                  option => option.value === data.role
                );
                if (selectedRoleOption) {
                  setSelectedRole(selectedRoleOption);
                }
              }
            }
          } catch (error) {
            handleApiError(error, 'Error loading chat session');
          }
        };
  
        loadSession();
      }
    };
  
    window.addEventListener('focus', handleFocus);
    return () => window.removeEventListener('focus', handleFocus);
  }, [apiEndpoint, sessionId, open, customization.roleOptions, getWelcomeMessage, handleApiError, selectedRole]);  

  useEffect(() => {
    startTypingAnimation();
  }, []);

  const startTypingAnimation = () => {
    setIsTypingIndicatorVisible(true);
    setTimeout(() => {
      setIsTypingIndicatorVisible(false);
      setShowPopupMessage(true);
      setTimeout(() => {
        if (!open) {
          setShowPopupMessage(false);
          setShowUnreadIndicator(true);
        }
      }, WARNING_TIMEOUT_MS);
    }, TYPING_ANIMATION_DURATION);
  };

  const handleSendMessage = useCallback(async () => {
    const messageText = inputValue.trim();
    if (!messageText) return;

    // Check if role selection is required but not selected
    if (customization.roleOptions && customization.roleRequired && !selectedRole) {
      handleApiError(null, 'Please select a role before sending a message.');
      return;
    }

    const newMessage = { text: messageText, sender: 'user', type: 'normal' };
    setMessages(prev => [...prev, newMessage]);
    setInputValue('');
    setIsTyping(true);

    const timeout = setTimeout(() => {
      setMessages(prev => [
        ...prev,
        {
          text: "Het kan even duren voordat ik antwoord geef.",
          sender: 'bot',
          type: 'warning',
        },
      ]);
    }, WARNING_TIMEOUT_MS);
    setWarningTimeout(timeout);
    warningTimeoutRef.current = timeout;

    try {
      const response = await fetch(`${apiEndpoint}generate_response`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          session_id: sessionId,
          message: messageText,
          role: selectedRole?.value,
          url: window.location.href,
        }),
      });

      const data = await response.json();
      if (data.response) {
        setMessages(prevMessages => {
          const filteredMessages = prevMessages.filter(msg => msg.type !== 'warning');
          return [...filteredMessages, { 
            text: data.response, 
            sender: 'bot', 
            type: 'normal',
            messageId: data.messageId 
          }];
        });
        
        if (data.session_id) {
          setSessionId(data.session_id);
          localStorage.setItem('chatSessionId', data.session_id);
        }
      } else if (data.error) {
        handleApiError(data.error, 'Er is een fout opgetreden bij het genereren van het antwoord. Probeer het opnieuw.');
      }
    } catch (error) {
      handleApiError(error, 'Er is een fout opgetreden bij het verbinden met de server. Probeer het later opnieuw.');
    } finally {
      setIsTyping(false);
      clearTimeout(warningTimeoutRef.current);
    }
  }, [inputValue, apiEndpoint, sessionId, selectedRole, customization.roleOptions, customization.roleRequired, handleApiError]);

  const handleFeedback = async (messageId, feedback) => {
    try {
      const response = await fetch(`${apiEndpoint}submit_feedback`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message_id: messageId, feedback }),
      });
      
      if (response.ok) {
        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.messageId === messageId 
              ? { ...msg, feedback: feedback || null } // Initialize as null if empty
              : msg
          )
        );
      } else {
        handleApiError(null, 'Error submitting feedback');
      }
    } catch (error) {
      handleApiError(error, 'Error submitting feedback');
    }
  };  

  const handleResetSession = async () => {
    if (sessionId) {
      try {
        await fetch(`${apiEndpoint}reset_session`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ session_id: sessionId }),
        });
      } catch (error) {
        handleApiError(error, 'Error resetting session');
      }
    }
    
    localStorage.removeItem('chatSessionId');
    setSessionId(null);
    setSelectedRole(null);
    setMessages([getWelcomeMessage()]);
  };

  const handlePrivacyClick = () => {
    setMessages(prev => [
      ...prev,
      { text: customization.privacyMessage, sender: 'bot', type: 'privacy' },
    ]);
  };

  const handleRoleSelection = (role) => {
    // Only allow role selection if no role is selected
    if (!selectedRole) {
      setSelectedRole(role);
      setMessages(prev => [
        ...prev,
        { 
          text: role.message || `You've selected the role: ${role.label}`, 
          sender: 'bot', 
          type: 'role_selection' 
        },
      ]);
    }
  };

  // UI Event Handlers
  const handleDrawerOpen = () => {
    setOpen(true);
    setShowPopupMessage(false);
    setShowUnreadIndicator(false);
  };

  const handleDrawerClose = () => setOpen(false);
  const handleInputChange = (event) => setInputValue(event.target.value);
  const handleKeyPress = (event) => event.key === 'Enter' && handleSendMessage();
  const handlePopupClick = handleDrawerOpen;

  return (
    <>
      {!open && (
        <>
          <ChatFab
            onClick={handleDrawerOpen}
            customization={customization}
            showUnreadIndicator={showUnreadIndicator}
          />
          {isTypingIndicatorVisible && (
            <TypingIndicatorWrapper
              side={customization.openButtonSide}
              horizontalMargin={customization.openButtonHorizontalMargin}
              verticalMargin={customization.openButtonVerticalMargin}
              backgroundColor={customization.primaryBackgroundColor}
            >
              <TypingIndicator />
            </TypingIndicatorWrapper>
          )}
          {showPopupMessage && (
            <MessagePopup
              side={customization.openButtonSide}
              horizontalMargin={customization.openButtonHorizontalMargin}
              verticalMargin={customization.openButtonVerticalMargin}
              fabSize={56}
              backgroundColor={customization.primaryBackgroundColor}
              textColor={customization.primaryTextColor}
              fontFamily={customization.fontFamily}
              popupMessage={customization.popupMessage || 'Waar kan ik je mee helpen?'}
              onClose={() => setShowPopupMessage(false)}
              onClick={handlePopupClick}
            />
          )}
        </>
      )}
      <ChatDrawer
        open={open}
        onClose={handleDrawerClose}
        customization={customization}
        messages={messages}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onKeyPress={handleKeyPress}
        onSendMessage={handleSendMessage}
        onFeedback={handleFeedback}
        isTyping={isTyping}
        roleOptions={customization.roleOptions}
        onRoleSelection={handleRoleSelection}
        selectedRole={selectedRole}
        onResetSession={handleResetSession}
        onPrivacyClick={handlePrivacyClick}
        isLoadingHistory={isLoadingHistory}
      />
    </>
  );
});

export default ChatInterface;
