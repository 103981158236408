import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import { InputAreaContainer, MessageField, SendButton } from '../styles/InputAreaStyles';

/**
 * InputArea component provides a text input field with a send button
 * for chat message submission
 */
const InputArea = ({
  inputValue,
  onInputChange,
  onKeyPress,
  onSendMessage,
  buttonColor = '#000000',
  backgroundColor = '#ffffff',
  disabled = false,
  maxLength,
  placeholder = 'Typ uw bericht...',
  errorMessage = '',
  isLoading = false,
}) => {
  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent default to avoid form submission
      if (inputValue.trim() && !disabled && !isLoading) {
        onSendMessage();
      }
      return; // Return early to prevent onKeyPress from being called
    }
    // Only call onKeyPress for non-Enter keypresses
    onKeyPress?.(event);
  }, [inputValue, disabled, isLoading, onSendMessage, onKeyPress]);

  const handleSendClick = useCallback((event) => {
    event.preventDefault(); // Prevent any potential form submission
    if (inputValue.trim() && !disabled && !isLoading) {
      onSendMessage();
    }
  }, [inputValue, disabled, isLoading, onSendMessage]);

  const characterCount = inputValue.length;
  const isOverLimit = maxLength && characterCount > maxLength;
  const showCounter = maxLength && characterCount >= maxLength * 0.8;
  const characterCountColor = isOverLimit ? '#ff0000' : '#666666';

  return (
    <InputAreaContainer
      style={{ backgroundColor }}
      role="form"
      aria-label="Message input form"
    >
      <MessageField
        label=""
        variant="outlined"
        value={inputValue}
        onChange={onInputChange}
        onKeyDown={handleKeyPress}
        fullWidth
        disabled={disabled || isLoading}
        error={!!errorMessage || isOverLimit}
        helperText={
          errorMessage || (showCounter ? (
            <Typography 
              variant="caption" 
              style={{ color: characterCountColor }}
            >
              {`${characterCount}/${maxLength}`}
            </Typography>
          ) : null)
        }
        inputProps={{
          maxLength: maxLength,
          'aria-label': 'Message input',
          'aria-describedby': errorMessage ? 'error-message' : undefined,
          style: { lineHeight: '24px' },
        }}
        multiline={false}
        placeholder={placeholder}
        FormHelperTextProps={{
          id: errorMessage ? 'error-message' : undefined,
          style: { margin: 0, position: 'absolute', bottom: -20 },
        }}
      />
      <SendButton
        onClick={handleSendClick}
        style={{ color: buttonColor }}
        disabled={disabled || isLoading || !inputValue.trim() || isOverLimit}
        aria-label="Send message"
        title="Send message"
      >
        <SendIcon />
      </SendButton>
    </InputAreaContainer>
  );
};

InputArea.propTypes = {
  /** Current value of the input field */
  inputValue: PropTypes.string.isRequired,
  /** Handler for input change events */
  onInputChange: PropTypes.func.isRequired,
  /** Handler for key press events */
  onKeyPress: PropTypes.func,
  /** Handler for send message action */
  onSendMessage: PropTypes.func.isRequired,
  /** Color of the send button */
  buttonColor: PropTypes.string,
  /** Background color of the input area */
  backgroundColor: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** Maximum length of the input */
  maxLength: PropTypes.number,
  /** Placeholder text for the input field */
  placeholder: PropTypes.string,
  /** Error message to display */
  errorMessage: PropTypes.string,
  /** Whether the form is in a loading state */
  isLoading: PropTypes.bool,
};

export default memo(InputArea);
