import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Box, Typography } from '@mui/material';
import ChatHeader from './ChatHeader';
import MessageList from './MessageList';
import InputArea from './InputArea';
import ChatFooter from './ChatFooter';
import { StyledChatDrawer } from '../styles/ChatDrawerStyles';

const LoadingOverlay = memo(({ 
  backgroundColor = '#ffffff',
  loadingText = 'Laden van chat geschiedenis...'
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      backgroundColor: backgroundColor || '#ffffff',
      gap: 2,
    }}
    role="progressbar"
    aria-label="Loading chat history"
    aria-busy="true"
  >
    <CircularProgress size={40} />
    <Typography variant="body1" color="textSecondary">
      {loadingText}
    </Typography>
  </Box>
));

LoadingOverlay.propTypes = {
  backgroundColor: PropTypes.string,
  loadingText: PropTypes.string,
};

const ChatDrawer = ({
  // Drawer state
  open,
  onClose,
  
  // Customization
  customization = {
    openButtonSide: 'right',
    openButtonHorizontalMargin: 20,
    openButtonVerticalMargin: 20,
    fontFamily: 'inherit',
    assistantPicture: '',
    assistantName: 'Assistant',
    primaryTextColor: '#000000',
    primaryBackgroundColor: '#ffffff',
    secondaryBackgroundColor: '#f5f5f5',
    secondaryTextColor: '#000000',
    messageListBackgroundColor: '#ffffff',
    inputPlaceholder: 'Typ uw bericht...',
    maxInputLength: 500,
    loadingText: 'Loading...',
    footerPrefix: '',
    footerLinkText: '',
    footerLinkUrl: '',
    roleOptions: null,
    roleRequired: false,
  },
  
  // Message handling
  messages,
  inputValue,
  onInputChange,
  onKeyPress,
  onSendMessage,
  onFeedback,
  
  // State indicators
  isTyping = false,
  isLoadingHistory = false,
  
  // Role handling
  roleOptions = null,
  selectedRole = null,
  onRoleSelection = () => {},
  
  // Additional actions
  onResetSession,
  onPrivacyClick,
  
  // Error handling
  error = null,
}) => {
  const handleDrawerClose = (event) => {
    if (event.target.closest('.MuiDrawer-paper')) {
      return;
    }
    onClose();
  };

  return (
    <StyledChatDrawer
      anchor={customization.openButtonSide}
      open={open}
      onClose={handleDrawerClose}
      fontFamily={customization.fontFamily}
      horizontalMargin={customization.openButtonHorizontalMargin}
      verticalMargin={customization.openButtonVerticalMargin}
      ModalProps={{
        BackdropProps: { 
          style: { backgroundColor: 'transparent' },
          onClick: handleDrawerClose,
        },
      }}
      style={{ zIndex: 99998 }}
      role="dialog"
      aria-label="Chat interface"
      aria-modal="true"
    >
      <ChatHeader
        assistantPicture={customization.assistantPicture}
        assistantName={customization.assistantName}
        color={customization.primaryTextColor}
        backgroundColor={customization.primaryBackgroundColor}
        onClose={onClose}
        onReset={onResetSession}
        onPrivacyClick={onPrivacyClick}
        isResetting={isLoadingHistory}
        disabled={!!error}
      />

      {error ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            padding: 2,
            textAlign: 'center',
            backgroundColor: customization.messageListBackgroundColor,
          }}
          role="alert"
        >
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Probeer het later opnieuw.
          </Typography>
        </Box>
      ) : isLoadingHistory ? (
        <LoadingOverlay 
          backgroundColor={customization.messageListBackgroundColor}
          loadingText={customization.loadingText}
        />
      ) : (
        <>
          <MessageList
            messages={messages}
            backgroundColor={customization.messageListBackgroundColor}
            primaryBackgroundColor={customization.primaryBackgroundColor}
            primaryTextColor={customization.primaryTextColor}
            secondaryBackgroundColor={customization.secondaryBackgroundColor}
            secondaryTextColor={customization.secondaryTextColor}
            onFeedback={onFeedback}
            isTyping={isTyping}
            roleOptions={customization.roleOptions}
            onRoleSelection={onRoleSelection}
            roleButtonStyles={{
              bgcolor: customization.primaryBackgroundColor,
              textcolor: customization.secondaryBackgroundColor,
              bordercolor: customization.secondaryBackgroundColor,
            }}
            selectedRole={selectedRole}
          />
          <InputArea
            inputValue={inputValue}
            onInputChange={onInputChange}
            onKeyPress={onKeyPress}
            onSendMessage={onSendMessage}
            buttonColor={customization.secondaryBackgroundColor}
            backgroundColor={customization.primaryBackgroundColor}
            disabled={customization.roleOptions && customization.roleRequired && !selectedRole}
            placeholder={customization.inputPlaceholder}
            maxLength={customization.maxInputLength}
            isLoading={isTyping}
          />
          <ChatFooter
            prefix={customization.footerPrefix}
            linkText={customization.footerLinkText}
            linkUrl={customization.footerLinkUrl}
            backgroundColor={customization.primaryBackgroundColor}
            textColor={customization.primaryTextColor}
          />
        </>
      )}
    </StyledChatDrawer>
  );
};

ChatDrawer.propTypes = {
  // Drawer state
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  
  // Customization
  customization: PropTypes.shape({
    openButtonSide: PropTypes.oneOf(['left', 'right']),
    openButtonHorizontalMargin: PropTypes.number,
    openButtonVerticalMargin: PropTypes.number,
    fontFamily: PropTypes.string,
    assistantPicture: PropTypes.string,
    assistantName: PropTypes.string,
    primaryTextColor: PropTypes.string,
    primaryBackgroundColor: PropTypes.string,
    secondaryBackgroundColor: PropTypes.string,
    secondaryTextColor: PropTypes.string,
    messageListBackgroundColor: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    maxInputLength: PropTypes.number,
    loadingText: PropTypes.string,
    footerPrefix: PropTypes.string,
    footerLinkText: PropTypes.string,
    footerLinkUrl: PropTypes.string,
    roleOptions: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })),
    roleRequired: PropTypes.bool,
  }),
  
  // Message handling
  messages: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    sender: PropTypes.oneOf(['user', 'bot']).isRequired,
    type: PropTypes.oneOf(['normal', 'error', 'warning', 'welcome', 'privacy', 'role_selection']).isRequired,
    messageId: PropTypes.string,
    feedback: PropTypes.oneOf(['positive', 'negative', null]),
  })).isRequired,
  inputValue: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  onFeedback: PropTypes.func.isRequired,
  
  // State indicators
  isTyping: PropTypes.bool,
  isLoadingHistory: PropTypes.bool,
  
  // Role handling
  roleOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  selectedRole: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    message: PropTypes.string,
  }),
  onRoleSelection: PropTypes.func,
  
  // Additional actions
  onResetSession: PropTypes.func.isRequired,
  onPrivacyClick: PropTypes.func.isRequired,
  
  // Error handling
  error: PropTypes.string,
};

export default memo(ChatDrawer);
