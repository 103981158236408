import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TypingIndicatorContainer, Dot } from '../styles/TypingIndicatorStyles';

const TypingIndicator = ({ ariaLabel = 'Loading message...' }) => (
  <TypingIndicatorContainer
    role="status"
    aria-label={ariaLabel}
  >
    <Dot />
    <Dot />
    <Dot />
  </TypingIndicatorContainer>
);

TypingIndicator.propTypes = {
  ariaLabel: PropTypes.string,
};

export default memo(TypingIndicator);
