import styled from '@emotion/styled';
import { Box, IconButton } from '@mui/material';

export const ChatHeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
`;

export const ChatHeaderLogo = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const ChatHeaderMenu = styled(IconButton)`
  color: ${({ style }) => style.color};
`;

export const ChatHeaderClose = styled(IconButton)`
  color: ${({ style }) => style.color};
`;
