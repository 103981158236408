import styled from '@emotion/styled';
import { Box, Paper } from '@mui/material';

export const TypingBubbleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
`;

export const TypingBubblePaper = styled(Paper)`
  border-radius: 10px;
  padding: 12px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  background-color: ${({ style }) => style.backgroundColor};
  color: #333;
  display: flex;
  align-items: center;
`;
