import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import ChatInterface from './ChatInterface';

function initChatInterface(options) {
  const chatContainer = document.createElement('div');
  chatContainer.id = 'chat-interface-root';
  document.body.appendChild(chatContainer);

  let chatInterfaceRef = null;

  const ChatWrapper = () => {
    const wrapperRef = useRef(null);

    useEffect(() => {
      if (wrapperRef.current) {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('openChatInterface') === 'true') {
          wrapperRef.current.openChatInterface();
        }
      }
    }, []);

    return (
      <ChatInterface
        ref={(ref) => {
          chatInterfaceRef = ref;
          wrapperRef.current = ref;
        }}
        clientId={options.clientId}
      />
    );
  };

  const root = ReactDOM.createRoot(chatContainer);
  root.render(<ChatWrapper />);

  window.openChatInterface = () => {
    if (chatInterfaceRef) {
      chatInterfaceRef.openChatInterface();
    }
  };
}

export default initChatInterface;
