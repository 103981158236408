import styled from '@emotion/styled';
import { Box, Paper, Button } from '@mui/material';

export const MessageListContainer = styled(Box)`
  flex-grow: 1;
  overflow: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
`;

export const MessageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: ${({ sender }) => (sender === 'user' ? 'flex-end' : 'flex-start')};
  margin-top: 15px;
`;

export const MessagePaper = styled(Paper)`
  max-width: 90%;
  border-radius: ${({ sender }) => (sender === 'user' ? '10px 10px 0 10px' : '10px 10px 10px 0')};
  padding: 12px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  background-color: ${({ sender, style }) =>
    sender === 'user' ? style.secondaryBackgroundColor : style.primaryBackgroundColor};
  color: ${({ sender, style }) => (sender === 'user' ? style.secondaryTextColor : style.primaryTextColor)};
`;

export const FeedbackContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`;

export const RoleButtonsContainer = styled(Box)`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const RoleButton = styled(Button, {
  shouldForwardProp: prop => !['bgcolor', 'textcolor', 'bordercolor', 'isHidden', 'isSelected'].includes(prop)
})`
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ textcolor }) => textcolor};
  border: 2px solid ${({ bordercolor }) => bordercolor};
  display: ${({ isHidden }) => isHidden ? 'none' : 'inline-flex'};

  &:hover {
    background-color: ${({ bgcolor, isSelected }) => isSelected ? bgcolor : 'rgba(0, 0, 0, 0.04)'};
  }

  &:disabled {
    background-color: ${({ bgcolor }) => bgcolor};
    color: ${({ textcolor }) => textcolor};
    border: 2px solid ${({ bordercolor }) => bordercolor};
  }
`;

export const ErrorMessage = styled(Box)`
  display: flex;
  align-items: center;
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
`;
