const getConfigByClientId = async (clientId) => {
  const configurations = [
    {
      clientId: 'leerKRACHT_assistent',
      apiEndpoint: 'https://lk-api.aivoorimpact.nl/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/leerkracht_assistent.png',
        assistantName: 'leerKRACHT AI-assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: `Hoi! Ik geef graag antwoord op al je vragen over Stichting leerKRACHT.\n\nVoordat je jouw vraag stelt, wil ik je erop wijzen dat ik een kunstmatige intelligentie ben. Komen we er samen niet uit? Dan kan je ook [persoonlijk contact met ons](https://stichting-leerkracht.nl/contact/) opnemen.\n\nWaar kan ik je mee helpen?`,
        privacyMessage: `Door met de leerKRACHT assistent te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).`,
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: false,
        maxInputLength: 500,
        roleOptions: [
          { label: 'Leraar', value: 'leraar', message: 'Welkom, leraar! Als leraar ben je de sleutel tot kwaliteitsverbetering in het onderwijs. Op onze [speciale pagina voor leraren](https://stichting-leerkracht.nl/leerkrachtvoorleraren/) vind je meer informatie over hoe je samen met je team elke dag een beetje beter kunt worden. Je kunt ook meer lezen over [het leerKRACHT-programma](https://stichting-leerkracht.nl/leerkracht-programma/) waarmee je samen met je collega\'s werkt aan beter onderwijs. Waar kan ik je mee helpen?' },
          { label: 'Schoolleider', value: 'schoolleider', message: 'Welkom, schoolleider! Als schoolleider sta je voor de uitdaging om met je team een continue verbetercultuur te creëren. Bekijk onze [speciale pagina voor schoolleiders](https://stichting-leerkracht.nl/leerkrachtvoorschoolleiders/) voor meer informatie over het leerKRACHT-programma. Ook hebben we een interessant artikel over [je cruciale rol als schoolleider](https://stichting-leerkracht.nl/kennisbank/rol-van-schoolleider/) in het creëren van een verbetercultuur. Waar kan ik je mee helpen?' },
          { label: 'Bestuurder', value: 'bestuurder', message: 'Welkom, bestuurder! Als bestuurder wil je waarschijnlijk weten hoe je de kwaliteit van het onderwijs in je stichting kunt verbeteren. Bekijk onze [speciale pagina voor bestuurders](https://stichting-leerkracht.nl/bestuurders/) voor meer informatie over het creëren van een verbetercultuur. Ook kun je je verdiepen in ons [intersectoraal leiderschapsprogramma](https://stichting-leerkracht.nl/besturenprogramma/) waar je kunt leren van Nederlandse topinstellingen. Waar kan ik je mee helpen?' },
        ],
      },
    },
    {
      clientId: 'doorloopjes_assistent',
      apiEndpoint: 'https://dl-api.aivoorimpact.nl/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#fec91c',
        secondaryTextColor: '#000000',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/doorloopjes_assistent.png',
        assistantName: 'Doorloopjes Assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: 'Welkom, ik ben de Doorloopjes-assistent. Ik ben hier om je te helpen bij het vinden van de juiste Doorloopjes en kan jou van informatie voorzien over evidence informed werken.',
        privacyMessage: `Door met de Doorloopjes Assistent te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).`,
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: false,
        maxInputLength: 500,
        roleOptions: null,
      },
    },
    {
      clientId: 'online_academie_assistent',
      apiEndpoint: 'https://online-academie-assistent.replit.app/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/online_academie_assistent.png',
        assistantName: 'Online Academie assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: `Hoi! Ik geef graag antwoord op al je vragen over de Online Academie van leerKRACHT.\n\nVoordat je jouw vraag stelt, wil ik je erop wijzen dat ik een kunstmatige intelligentie ben. Komen we er samen niet uit? Dan kan je ook [persoonlijk contact met ons](https://leerkracht-academie.nl/contact/) opnemen.\n\nWaar kan ik je mee helpen? `,
        privacyMessage: `Door met de Online Academie assistent te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).`,
        openButtonSide: 'right',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: true,
        maxInputLength: 500,
        roleOptions: [
          { label: 'Leraar/Overig', value: 'leerkracht', message: 'Welkom, leraar of overig! Waar kan ik je mee helpen?' },
          { label: 'Schoolcoach', value: 'schoolcoach', message: 'Welkom, schoolcoach! Waar kan ik je mee helpen?' },
          { label: 'Schoolleider', value: 'schoolleider', message: 'Welkom, schoolleider! Waar kan ik je mee helpen?' },
          { label: 'Senior Schoolcoach', value: 'senior_schoolcoach', message: 'Welkom, senior schoolcoach! Waar kan ik je mee helpen?' },
          { label: 'Senior Schoolleider', value: 'senior_schoolleider', message: 'Welkom, senior schoolleider! Waar kan ik je mee helpen?' },
        ],
      },
    },
    {
      clientId: 'je_leefstijl_als_medicijn_assistent',
      apiEndpoint: 'https://jlam-api.aivoorimpact.nl/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/je_leefstijl_als_medicijn_assistent.png',
        assistantName: 'Lampie - AI assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: `Hoi! Ik ben Lampie. Ik geef graag antwoord op al je vragen over leefstijl (voeding, bewegen, slaap, etc.) en help je met het vinden van leefstijlprofessionals en -initiatieven bij jou in de buurt. Ik kan je geen medisch advies geven.\n\nIk maak gebruik van kunstmatige intelligentie voor het beantwoorden van jouw vraag. Komen we er samen niet uit? Dan kan je ook persoonlijk contact met ons opnemen.\n\nWaar kan ik je mee helpen?`,
        privacyMessage: `Door met Lampie te chatten, ga je akkoord met onze [privacyverklaring](https://jeleefstijlalsmedicijn.nl/privacyverklaring/). Ben je benieuwd hoe Lampie werkt? Bekijk dan [deze pagina](https://jeleefstijlalsmedicijn.nl/zo-werkt-lampie-de-ai-assistent/).`,
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: false,
        maxInputLength: 500,
        roleOptions: null,
      },
    },
    {
      clientId: 'test_assistent',
      apiEndpoint: 'http://localhost/test/',
      customization: {
        primaryBackgroundColor: '#ffffff',
        primaryTextColor: '#31373d',
        secondaryBackgroundColor: '#34a3da',
        secondaryTextColor: '#ffffff',
        messageListBackgroundColor: '#f5f5f5',
        fontFamily: 'Arial, sans-serif',
        assistantPicture: 'https://chat-interface.aivoorimpact.nl/images/leerkracht_assistent.png',
        assistantName: 'leerKRACHT AI-assistent',
        popupMessage: 'Hoi! Waar kan ik je mee helpen?',
        welcomeMessage: `Hoi! Ik geef graag antwoord op al je vragen over Stichting leerKRACHT.\n\nVoordat je jouw vraag stelt, wil ik je erop wijzen dat ik een kunstmatige intelligentie ben. Komen we er samen niet uit? Dan kan je ook [persoonlijk contact met ons](https://stichting-leerkracht.nl/contact/) opnemen.\n\nWaar kan ik je mee helpen?`,
        privacyMessage: `Door met de leerKRACHT assistent te chatten, ga je akkoord met onze [privacyverklaring](https://stichting-leerkracht.nl/privacy-statement/).`,
        openButtonSide: 'left',
        openButtonHorizontalMargin: 20,
        openButtonVerticalMargin: 20,
        roleRequired: false,
        maxInputLength: 500,
        roleOptions: [
          { label: 'Leraar', value: 'leraar', message: 'Welkom, leraar! Als leraar ben je de sleutel tot kwaliteitsverbetering in het onderwijs. Op onze [speciale pagina voor leraren](https://stichting-leerkracht.nl/leerkrachtvoorleraren/) vind je meer informatie over hoe je samen met je team elke dag een beetje beter kunt worden. Je kunt ook meer lezen over [het leerKRACHT-programma](https://stichting-leerkracht.nl/leerkracht-programma/) waarmee je samen met je collega\'s werkt aan beter onderwijs. Waar kan ik je mee helpen?' },
          { label: 'Schoolleider', value: 'schoolleider', message: 'Welkom, schoolleider! Als schoolleider sta je voor de uitdaging om met je team een continue verbetercultuur te creëren. Bekijk onze [speciale pagina voor schoolleiders](https://stichting-leerkracht.nl/leerkrachtvoorschoolleiders/) voor meer informatie over het leerKRACHT-programma. Ook hebben we een interessant artikel over [je cruciale rol als schoolleider](https://stichting-leerkracht.nl/kennisbank/rol-van-schoolleider/) in het creëren van een verbetercultuur. Waar kan ik je mee helpen?' },
          { label: 'Bestuurder', value: 'bestuurder', message: 'Welkom, bestuurder! Als bestuurder wil je waarschijnlijk weten hoe je de kwaliteit van het onderwijs in je stichting kunt verbeteren. Bekijk onze [speciale pagina voor bestuurders](https://stichting-leerkracht.nl/bestuurders/) voor meer informatie over het creëren van een verbetercultuur. Ook kun je je verdiepen in ons [intersectoraal leiderschapsprogramma](https://stichting-leerkracht.nl/besturenprogramma/) waar je kunt leren van Nederlandse topinstellingen. Waar kan ik je mee helpen?' },
        ],
      },
    },
  ];

  const config = configurations.find(c => c.clientId === clientId);
  if (!config) {
    throw new Error(`Configuration not found for client ID: ${clientId}`);
  }
  return config;
};

export default getConfigByClientId;
